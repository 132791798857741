import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
import { FileWithFlippedCornerIcon } from '@smallstep/step-ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const Link = makeShortcode("Link");
const ContentLink = makeShortcode("ContentLink");
const AlertTitle = makeShortcode("AlertTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Smallstep SSH is a commercial product that delivers a complete end-to-end SSH workflow combining the benefits of modern identity providers, SSH certificates, and proven standard technologies like OpenSSH and OAuth. The Professional Edition is a managed offering that takes the open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` and adds automatic access control, end-to-end user lifecycle management, event activity logging, and reporting.`}</p>
    <Alert severity="info" mb={4} mdxType="Alert">
  <div>
    Are you looking for the open source <Code mdxType="Code">step-ca</Code> documentation? <Link href="/docs/step-ca" mdxType="Link">Find it here</Link>.
  </div>
    </Alert>
    <p><img parentName="p" {...{
        "src": "/graphics/docs-ssh-logo.png",
        "alt": "Smallstep SSH",
        "title": "Smallstep SSH"
      }}></img></p>
    <p>{`Before you begin, `}<a parentName="p" {...{
        "href": "https://smallstep.com/signup?product=ssh"
      }}>{`register for a smallstep account`}</a>{` if you haven't already.`}</p>
    <h2>{`Step 1: Set up your client`}</h2>
    <p>{`Start by configuring SSH access on your laptop:`}</p>
    <ContentLink icon={<FileWithFlippedCornerIcon mdxType="FileWithFlippedCornerIcon" />} href="/docs/ssh/client" mdxType="ContentLink">
  Client Quickstart Guide &gt;
    </ContentLink>
    <h2>{`Step 2: Register Hosts`}</h2>
    <p>{`Register your hosts with Smallstep SSH:`}</p>
    <ContentLink icon={<FileWithFlippedCornerIcon mdxType="FileWithFlippedCornerIcon" />} href="/docs/ssh/hosts" mdxType="ContentLink">
  Host Quickstart Guide &gt;
    </ContentLink>
    <h2>{`Step 3: Set up Single Sign-on and Directory Sync`}</h2>
    <p>{`If you want to use Smallstep SSH in multi-user mode, you can sync with an identity provider.
Note: Single Sign-on through your identity provider requires SSH Professional + Team level account or higher. `}{`[Click here for more pricing information]`}{` (/sso-ssh/pricing/#pricing).`}</p>
    <ContentLink icon={<FileWithFlippedCornerIcon mdxType="FileWithFlippedCornerIcon" />} href="/docs/ssh/azure-ad" mdxType="ContentLink">
  Azure AD Quickstart Guide &gt;
    </ContentLink>
    <ContentLink icon={<FileWithFlippedCornerIcon mdxType="FileWithFlippedCornerIcon" />} href="/docs/ssh/g-suite" mdxType="ContentLink">
  G Suite Quickstart Guide &gt;
    </ContentLink>
    <ContentLink icon={<FileWithFlippedCornerIcon mdxType="FileWithFlippedCornerIcon" />} href="/docs/ssh/okta" mdxType="ContentLink">
  Okta Quickstart Guide &gt;
    </ContentLink>
    <Alert severity="info" mt={2} mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Don't see your identity provider?</AlertTitle>
  <div>
    We may still be able to work with you. Please <Link href="mailto:support@smallstep.com" mdxType="Link">contact us</Link> and tell us a little about your setup.
  </div>
    </Alert>
    <h2>{`Step 4: Grant Access`}</h2>
    <p>{`Establish access control rules:`}</p>
    <ContentLink icon={<FileWithFlippedCornerIcon mdxType="FileWithFlippedCornerIcon" />} href="/docs/ssh/acls" mdxType="ContentLink">
  Access Control Guide &gt;
    </ContentLink>
    <h2>{`Looking For Technical Details On The Product?`}</h2>
    <p>{`You can read more about the technical details of Smallstep SSH by visiting our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/sso-ssh/how-it-works"
      }}>{`how it works page`}</a>{`. Learn how we protect your data, see our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/security"
      }}>{`security page`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      